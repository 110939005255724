/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import Lightbox from "react-image-lightbox"
import PageWrapper from "../../layouts/PageWrapper"
import OrganisationImg from "../../img/orga.png"
import HomeLogo from "../../components/HomeLogo"

import "react-image-lightbox/style.css"

export default class OurOrganisationPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { isOpen } = this.state

    return (
      <PageWrapper>
        <Helmet>
          <title>Lounge - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <div className="section">
                    <HomeLogo />
                    <h1 className="title" id="title-line">
                      Bless the day
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hero organisation section">
          <div className="hero-body">
            <div className="container">
              <h2>get in lounge</h2>
              <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <h2 className="has-text-weight-semibold is-size-2">
                  Price Not Found
                </h2>
                <p className="is-size-5">Lorum ipsum dolor sit amit</p>
                
              </div>
            </div>
          </div>
        </div>
            </div>
          </div>
        </section>
      </PageWrapper>
   )
  }
}
